<template>
    <front-layout>
        <div class="flex-wrapper-full-background d-flex flex-wrap fill-height">
            <div class="intro mx-auto">
                <content-group :scopes="['terms']" :cols="12">
                    <template v-slot:item="{ item }">
                        <h1 class="text-center mb-11" v-text="'Terms of Use'"/>
                        <v-container>
                            <div v-html="item.text"/>
                        </v-container>
                    </template>
                </content-group>
            </div>
        </div>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import ContentGroup from "@/components/content/ContentGroup";

export default {
    name: 'Terms',
    components: {
        FrontLayout,
        ContentGroup
    },
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
